<script src="../../babel.config.js"></script>
<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!--<b-img
               class="w-50"
            :src="$data.$themeConfig.app.appLogoImage"
                  alt="logo"
          />-->
        </b-link>

        <validation-observer
                ref="loginValidation"
                #default="{invalid}"
        >
          <b-form
                  class="auth-login-form mt-2"
                  @submit.prevent
          >
            <b-form-group
                    label-for="email-or-phone"
                    label="RUT"
            >
              <validation-provider
                      #default="{ errors }"
                      name="Usuario"
                      rules="required"
              >
                <b-form-input
                        id="rut"
                        v-model="username"
                        name="login-email-phone"
                        :state="errors.length > 0 ? false : null"
                        placeholder="11111111-1"
                        autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Contraseña</label>

              </div>
              <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
              >
                <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                          id="password"
                          v-model="password"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false : null"
                          name="login-password"
                          placeholder="Password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <b-form-checkbox
                      id="remember-me"
                      v-model="status"
                      name="checkbox-1"
              >
                Recuerdame
              </b-form-checkbox>
            </b-form-group>

            <b-button

                    variant="primary"
                    type="submit"
                    block
                    :disabled="invalid"
                    @click="login"
            >
              <b-spinner
                      v-if="isLoading"
                      label="Loading..."
              />
              <span v-else>Ingresar</span>
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2" v-if="false">
          <span>¿Problemas con tu cuenta? comunicate </span>
          <b-link
                  class="text-warning"
                  href="mailto:sistemas@edutecno.com"
          >
            <span>sistemas@edutecno.com</span>
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import {
    BButton, BSpinner, BForm, BFormInput, BFormGroup, BCard, BLink, BImg, BCardText, BInputGroup, BInputGroupAppend, BFormCheckbox,
  } from 'bootstrap-vue'
  import { required, email } from '@validations'
  import { togglePasswordVisibility } from '@core/mixins/ui/forms'

  import { $themeConfig } from '@themeConfig'
  import store from '@/store'

  export default {
    components: {
      // BSV
      BButton,
      BSpinner,
      BForm,
      BFormInput,
      BFormGroup,
      BCard,
      BLink,
      BImg,
      BCardText,
      BInputGroup,
      BInputGroupAppend,
      BFormCheckbox,
      ValidationProvider,
      ValidationObserver,

    },
    mixins: [togglePasswordVisibility],
    data() {
      return {
        $themeConfig,
        username: '',
        password: '',
        status: '',
        // validation rules
        required,
        email,
        isLoading: false,
      }
    },
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },

    },
    methods: {
      login() {
        this.$refs.loginValidation.validate().then(success => {
          const user = this.username.split('-')
          this.isLoading = true
          if (success) {
            this.$store.dispatch('auth/login', {
              username: user[0],
              password: this.password,
            }).then(response => {
              //this.$store.commit('auth/SET_AUTH_USER', window.btoa(user[0]))
              //this.$store.commit('auth/SET_AUTH_PASSWORD', window.atob(this.password))
              this.$store.commit('auth/SET_AUTH_USER', user[0])
              this.$store.commit('auth/SET_AUTH_PASSWORD', this.password)
              this.getUserData().then(userResponse => {
                this.$handleSuccessResponse(response, 'Bienvenido/a')
                this.isLoading = false
                this.getUserMenu().then(() => {
                  this.getUserAccess()
                  const dash =  this.menudashboard(userResponse.rol)
                 console.log(dash);
                  setTimeout(() => this.$router.replace(dash), 10)
                })
              })
            }).catch(error => {
              this.$handleErrorResponse(error)
            })
          }
        })
      },
      menudashboard(rol) {
        let url=''
        rol.forEach(function(item){
          switch (item.name) {
            case 'empresa_corona' :
              url= '/dashboard-cursos';
              return ;
            case 'empresa_carozzi' :
              url= '/dashboard-carozzi';
              return ;
            case 'empresa_mutual' :
              url= '/dashboard-mutual';
              return ;
          }
        });

        return url!='' ? url:'/dashboard';
      },
      async getUserData() {
        return this.$store.dispatch('auth/getUserData')
      },
      async getUserMenu() {
        return this.$store.dispatch('auth/getUserMenu')
      },
      getUserAccess() {
       // return this.$store.dispatch('security/sendAcl', this.$router.options.routes)
      },
    },
  }
</script>

<style lang="scss">
  @import "@core/scss/vue/pages/page-auth.scss";
</style>
